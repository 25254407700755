



















































import { stringify }  from 'querystring'
import WysiwygBanner from '~/components/blocks/wysiwyg-banner/wysiwyg-banner'
import { urlValidators } from 'library/services/validators/urls'
import { NEWSLETTER_SIGNUP_KEY } from 'library/constants'
import GlobalNewsletterForm from 'library/components/global/newsletter-form'

export default

	components: { WysiwygBanner, GlobalNewsletterForm }

	inject: darkWrapper: default: false

	props: block: Object

	data: ->
		# Form state: init, success, error
		formState: 'init'
		step: @getFirstStepIndex()

	computed:

		classes: -> [
			'slate-wrapper' if @block.wrapper?[0]?.backgroundColor == '#303030'
		]

		signUpButtonTheme: ->
			return 'medium-grey' if @block.wrapper?[0]?.backgroundColor == '#303030'
			return 'primary'

		eventId: -> @block.eventId || @$route.query.event_id || ''

	methods:

		onFormStateChange: (state) ->
			if state=='success' then return @step = 2
			if state=='error' then return @step = 3
			if state=='init' then return @step = @getFirstStepIndex()

		getFirstStepIndex: ->
			return 0 if @block?.initialLayout == 'intro'
			return 1

